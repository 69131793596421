<template>
  <div class="bg-white">
  <NavBarNew></NavBarNew>
  <AboutHeroe></AboutHeroe>
  <AboutTabs></AboutTabs>
  <AboutCards></AboutCards>
  <!-- <Gallery></Gallery> -->
  <InstagramFeed></InstagramFeed>
  <OurTeam></OurTeam>
  <Footer></Footer>
  </div>
</template>

<script>
// @ is an alias to /src
import NavBarNew from "@/components/HomeScreen/NavBarNew.vue";
import AboutHeroe from "@/components/AboutUsScreen/AboutHeroe.vue";
import AboutTabs from "@/components/AboutUsScreen/AboutTabs.vue";
import AboutCards from "@/components/AboutUsScreen/AboutCards.vue";
// import Gallery from "@/components/AboutUsScreen/Gallery.vue";
import InstagramFeed from "@/components/AboutUsScreen/InstagramFeed.vue";
import OurTeam from "@/components/AboutUsScreen/OurTeam.vue";

import Footer from "@/components/HomeScreen/Footer.vue";
//import Header from '@/components/HomeScreen/header.vue'

export default {
  name: "About Us",
  components: {
    NavBarNew,
    AboutHeroe,
    AboutTabs,
    AboutCards,
    // Gallery,
    InstagramFeed,
    OurTeam,
    Footer,
  },
};
</script>