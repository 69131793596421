<template>
  <div class="aboutHeroeBg1">
    <div class="aboutHeroeBg2">
      <h1
        class="
          text-white
          font-bold
          text-5xl
          md:text-6xl
          leading-loose
          text-center
          pt-28
          md:pt-80
          md:pb-10
          md:px-96
        "
      >
        STEMLab
      </h1>
      <p class="text-white
          font-bold
          text-xl
          md:text-2xl
          xl:text-3xl
          leading-loose
          text-center
          md:pb-60">EDUKOJMË MENDJET, ZEMRAT DHE MENDJET E INOVATORËVE TË SË NESËRMES</p>

    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
@media only screen and (min-width: 1025px) {
  .aboutHeroeBg1 {
    background-image: url("./../../assets/Svg/AboutBGSvg1.svg");
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: 174px;
    /* background-size: 60%; */
  }
  .aboutHeroeBg2 {
    background-image: url("./../../assets/images/BgAbout3.png");
  
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: 108px;
    /* background-size: 60%; */
  }
}
@media only screen and (max-width: 1024px) {
  .aboutHeroeBg2 {
    background-image: url("./../../assets/images/BgAbout3.png");
    width: fit-content;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: 108px;
    /* background-size: 60%; */
  }
}
@media only screen and (max-width: 640px) {
   .aboutHeroeBg1 {
    background-image: url("./../../assets/Svg/AboutBGSvg1.svg");
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: 174px;
    /* background-size: 60%; */
  }
  .aboutHeroeBg2 {
    background-image: url("./../../assets/images/BgAbout3.png");
    width: fit-content;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: 108px;
    /* background-size: 60%; */
  }
}
</style>