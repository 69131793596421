<template>
    <div class="pt-40 md:px-24">
        <div class="grid grid-flow-row md:grid-flow-col">
        <div class="pr-4">
          <div class="">
            <div class="border-StemPurple pb-10 md:pb-20"></div>
          </div>
        </div>
        <div class="border-StemPurple border-l-2 grid grid-flow-row md:grid-flow-col">
          <div class="md:w-60">
            <h1 class="text-StemBlue font-bold text-4xl pl-4 text-left">
              Galeria
            </h1>
          </div>
        </div>
      </div>
      <div class="grid lg:grid-cols-6 lg:grid-rows-4 gap-5 grid-flow-row pt-10">
    <div class=" md:col-start-1 md:col-span-4 md:row-start-1 md:row-span-4">      
      <img src="@/assets/images/Gallery_img1.png" alt="">

    </div>
    <div class="md:col-start-5 md:col-span-2 md:row-start-1 md:row-span-2">
      <img src="@/assets/images/Gallery_img2.png" alt="">
    </div>
    <div class="md:col-start-5 md:col-span-2 md:row-start-3 md:row-span-2 ">

      <img src="@/assets/images/Gallery_img3.png" alt="">
    </div>

  </div>
  <div class="grid md:grid-cols-2 md:pt-5 md:gap-x-5 gap-5 grid-flow-row">
        <div class="">
      <img src="@/assets/images/Gallery_img4.png" alt="">
    </div>
    <div class="">
      <img src="@/assets/images/Gallery_img5.png" alt="">
    </div>
  </div>
      
        <!-- <div id="curator-feed-stemlab-in-layout">
            <a
                href="https://curator.io"
                target="_blank"
                class="crt-logo crt-tag"
            ></a>
        </div> -->
    </div>
</template>


<script type="text/javascript">
/* curator-feed-default-feed-layout */
(function () {
    var i, e, d = document, s = "script"; i = d.createElement("script"); i.async = 1; i.charset = "UTF-8";
    i.src = process.env.VUE_APP_CURATOR_INSTAGRAM_URL;
    e = d.getElementsByTagName(s)[0]; e.parentNode.insertBefore(i, e);
})();
</script>

<style>
    .crt-cover-flow-gallery-wrap {
        max-height: 60vh;
    }
</style>