<template>
<div class="md:px-24 pt-32 px-2 AboutCardsDeco1">
      <div class="border-StemPurple border-l-2 pl-4">
        <h1
          class="
            text-StemBlue
            font-bold
            text-4xl
            pr-4
            text-left
            
          "
        >
          Programet
        </h1>
      </div>
</div>
            
            <div class="py-10 md:px-24">
            <Carousel ref="flickity" :options="carouselOptions">
            <div class="carousel-cell" v-for="c in data1" :key="c">
              
              <img :src="c.image"  alt="">
              
            </div>
          </Carousel>
          </div>
</template>

<script>
import Carousel from "./../../components/base/carousel.vue"

import Program1 from "@/assets/images/Program1.png";
import Program2 from "@/assets/images/Program2.png";
import Program3 from "@/assets/images/Program3.png";
import Program5 from "@/assets/images/Program5.png";
import Program6 from "@/assets/images/Program6.png";
import Program7 from "@/assets/images/Program7.jpg";
import Program8 from "@/assets/images/Program8.jpg";
export default {
  components: {
    Carousel,
  },

  data() {
    return {
      data1: [
          { 
            id:0,
            image:Program1,
          },
          { 
            id:1,
            image:Program2,
          },
          {
            id:2,
            image:Program3,
            },
          {
            id:4,
            image:Program5,
          },
           { 
            id:5,
            image:Program6,
          },
          { 
            id:6,
            image:Program7,
          },
          {
            id:7,
            image:Program8,
          },
        
      ],
      carouselOptions: {
        initialIndex: 2,
        prevNextButtons: false,
        pageDots: false,
        wrapAround: true,
        autoPlay: 1000,
        // cellAlign: 'right',
        // contain: true

        // any options from Flickity can be used
      },
    };
  },

}
</script>

<style scoped>
@media only screen and (min-width: 1025px) {
.AboutCardsDeco1 {
    background-image: url("./../../assets/Svg/AboutCardsDeco1.svg");
    background-repeat: no-repeat;
    background-position-x: 90%;
    background-position-y: bottom;
  }
}
@media only screen and (max-width: 1024px) {
  .AboutCardsDeco1 {
    background-image: url("./../../assets/Svg/AboutCardsDeco1.svg");
    background-repeat: no-repeat;
    background-position-x: 90%;
    background-position-y: bottom;
  }

}
@media only screen and (max-width: 640px) {
  .AboutCardsDeco1 {
    background-image: url("./../../assets/Svg/AboutCardsDeco1.svg");
    background-repeat: no-repeat;
    background-position-x: 95%;
    background-position-y: 80%;
  }

}
.carousel-cell {
    display: block;
     margin-right: 80px;
  width: 180px;
  height: 180px;
}

</style>