<template>
  <div class="md:px-24 pt-32 px-2 AboutTabsBG1">
    <div class="border-StemPurple border-l-2 pl-4">
      <h1
        class="text-StemBlue font-bold text-4xl pr-4 text-left AboutTabsDeco1"
      >
        Për ne
      </h1>
      
    </div>
  </div>
  <div class="">
                <p
                  class="
                    text-StemTextSecond
                    font-normal
                    text-sm
                    pr-4
                    leading-loose
                    text-left
                    pt-10
                    md:w-1/3
                    md:ml-28
                  "
                >
                  
                </p>
  </div>

  <!-- tabs -->
  <div
    class="grid grid-flow-row md:grid-cols-5 md:grid-rows-1 px-2 md:px-24 pt-20"
  >
    <div class="row-start-2 md:col-start-1 md:col-span-4 md:row-start-1">
      <div
        class="
          text-left
          leading-loose
          text-StemTextSecond
          font-normal
          text-lg
          md:pr-40
          px-2
        "
      >
        <div class="" v-for="tab in tabs" :key="tab" v-show="tab.isActive">
          
          <div v-if="tab.name=='Metodologjia'">
          <div class="OddChild " v-for=" el in tab.desc" :key="el">
            <p class="">{{el}}</p>
            </div>
          </div>
           <div v-if="tab.name=='STEMLab'">
          <div class=" " v-for=" el in tab.desc" :key="el">
            <p class="">{{el}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row-start-1 md:col-start-5">
      <div class="">
        <ul class="grid grid-flow-col gap-5 md:grid-flow-row">
          <li
            class="text-right"
            v-for="tab in tabs"
            :key="tab"
            :class="{ 'is-active': tab.isActive }"
          >
            <button
              @click="selectTab(tab)"
              class="
                md:col-start-5
                row-start-1
                border-b-8 border-transparent
                hover:borderStemBlue
                text-left
                md:text-right
                text-StemBlue
                font-normal
                text-2xl
                py-2
                w-full
              "
            >
              <a class="cursor-pointer" :href="tab.href">{{ tab.name }}</a>
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="flex md:px-24 justify-center md:justify-end pt-10">
    <div class="">
      <button
        @click="$router.push({ path: 'contactus' })"
        class="
          rounded-lg
          bg-StemYellow
          hover:bg-transparent hover:text-StemYellow
          text-white
          border
          hover:border-StemYellow
          text-xl
          font-normal
          shadow-2xl
          px-10
          py-4
        "
      >
        Na kontaktoni
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tabs: [
        {
          name: "STEMLab",
          desc: ["Qendra për Edukim dhe Mësim Inovativ - STEMLab është një nismë e profesionistëve të shkencave natyrore dhe teknike dhe vjen si refleksion i nevojës për transformimin e mendësisë së edukimit dhe përshtatjen e tij konform kërkesave të kohës. Ata kanë përvojë pune në mjedise të ndryshme, me diversitet kulturor, grupmosha të ndryshme, gjuhë të ndryshme komunikimi dhe përvojë të gjatë jetësore në profesionine  tyre.","STEMLab është e fokusuar në edukimin e gjeneratave të reja dhe në krijimin e profesionistëve në STEM (Science, Technology, Engineering, Math) edukim, të cilët do të jenë ndërveprues me ambientin ku jetojnë, me kreativitet të lartë në punën e tyre dhe të gatshëm për edukim që zgjatë gjatë gjithë jetës."],
          isActive: true,
        },
        {
          name: "Metodologjia",
          desc: [`Ndërlidh (Connect)`,`Lidhu me përvoja të reja: Prezantohet detyra, duke ju lejuar nxënësve që të bëjnë pyetje sqaruese dhe të mbi-ndërtojnë njohuritë e tyre.`,`Ndërto (Construct)`,`Ndërtoni idetë tuaja: Çdo detyrë përfshin një aktivitet ndërtimi për të nxitur eksperimentimin dhe eksplorimin, dhe për të ndërtuar pajisje që mund të na nevojiten më vonë.`,`Reflekto (Contemplate)`,`Koha për reflektim: Nxënësit e shqyrtojnë atë që është mësuar dhe këmbejnë njohuritë me njëri-tjetrin`,`Vazhdo (Continue)`,`Zhvillimi i vazhdueshëm: Çdo detyrë përfundon me një detyrë të re që ndërtohet mbi atë që sapo është mësuar, duke i mbajtur nxënës  të motivuar dhe kureshtarë.`],
          isActive: false,
        },
      ],
    };
  },
  methods: {
    selectTab: function (selectedTab) {
      this.tabs.forEach((tab) => {
        tab.isActive = tab.name == selectedTab.name;
      });
    },
  },
  computed: {
    href() {
      return "#" + this.name.toLowerCase().replace(/ /g, "-");
    },
  },
};
</script>

<style>
@media only screen and (min-width: 1025px) {
  .AboutTabsBG1 {
    background-image: url("./../../assets/Svg/AboutTabsBG1.svg");
    background-repeat: no-repeat;
    background-position-x: left;
    background-position-y: bottom;
  }
  .AboutTabsDeco1 {
    background-image: url("./../../assets/Svg/AboutTabsDeco1.svg");
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: top;
  }
}
@media only screen and (max-width: 1024px) {
  .AboutTabsBG1 {
    background-image: url("./../../assets/Svg/AboutTabsBG1.svg");
    background-repeat: no-repeat;
    background-position-x: left;
    background-position-y: bottom;
  }
  .AboutTabsDeco1 {
    background-image: url("./../../assets/Svg/AboutTabsDeco1.svg");
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: top;
  }
}
@media only screen and (max-width: 640px) {
  .AboutTabsBG1 {
    background-image: url("./../../assets/Svg/AboutTabsBG1.svg");
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: bottom;
  }
  .AboutTabsDeco1 {
    background-image: url("./../../assets/Svg/AboutTabsDeco1.svg");
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: top;
  }
}
.is-active {
  border-bottom: 8px solid;
  border-bottom-color: #233558;
}
.OddChild:nth-child(odd){
  color: #2c3e50;
  font-weight: 600;
}
</style>