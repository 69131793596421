<template>
  <div class="pt-24 pb-20 px-4 circlebg">
    <div class="bg-no-repeat bg-auto h-full bg-top Teambg">
      <div class="grid grid-flow-row md:grid-flow-col">
        <div class="pr-4">
          <div class="">
            <div class="border-StemPurple pb-10 md:pb-20"></div>
          </div>
        </div>
        <div class="border-StemPurple border-l-2 grid grid-flow-row md:grid-flow-col">
          <div class="md:w-60">
            <h1 class="text-StemBlue font-bold text-4xl pl-4 text-left">
              Stafi
            </h1>
          </div>
        </div>
      </div>
      <!-- <div class="OurTeamDeco2">
        <div class="grid md:grid-cols-2 OurTeamDeco1">
          <div class="pt-40 md:pl-52">
            <h1 class="text-StemBlue font-semibold text-2xl pr-4 text-left">
              Ramadan Aliti
            </h1>
            <p
              class="
                text-StemLightRed
                font-normal
                text-lg
                pr-4
                leading-loose
                text-left
              "
            >
              Univeristy Professor
            </p>
            <p
              class="
                text-StemTextSecond
                font-normal
                text-lg
                pr-4
                leading-loose
                text-left
                pt-10
              "
            >
              Built Wicket longer admire do barton vanity itself do in it.
              Preferred to sportsmen it engrossed listening. Park gate sell they
              west hard for the.Built Wicket longer admire do barton vanity
              itself do in it. Preferred to sportsmen it engrossed listening.
              Park gate sell they west hard for the.
            </p>

            <div class="flex flex-col md:flex-row md:justify-between pt-10 pb-20">
              <div class="">
               
              </div>

              <div class="grid grid-flow-col pt-4 md:pt-0 gap-3 place-content-center">
                <button class="w-14 h-14">
                  <div
                    class="
                      flex
                      rounded-xl
                      hover:shadow-2xl
                      transition
                      duration-500
                      ease-in-out
                      transform
                      hover:-translate-y-1 hover:scale-110
                    "
                  >
                    <img src="./../../assets/Svg/arrowleftR.svg" alt="" />
                  </div>
                </button>
                <button class="w-14 h-14">
                  <div
                    class="
                      flex
                      rounded-xl
                      hover:shadow-2xl
                      transition
                      duration-500
                      ease-in-out
                      transform
                      hover:-translate-y-1 hover:scale-110
                    "
                  >
                    <img src="./../../assets/Svg/arrowrightR.svg" alt="" >
                  </div>
                </button>
              </div>
            </div>
          </div>
          <div class="OurTeambgtwo  flex justify-center">
         
          </div>
        </div>
      </div> -->
    </div>
  </div>
  
<Carousel ref="flickity" :options="carouselOptions">
  <div v-for="ppl in staff" :key="ppl" class="carousel-cell">
    <div class="OurTeamDeco2">
        <div class="grid md:grid-cols-2 OurTeamDeco1">
          <div class="pt-40 md:pl-52">
            <h1 class="text-StemBlue font-semibold text-2xl pr-4 text-left">
              {{ppl.name}}
            </h1>
            <p
              class="
                text-StemLightRed
                font-normal
                text-lg
                pr-4
                leading-loose
                text-left
              "
            >
              {{ppl.title}}
            </p>
            <p
              class="
                text-StemTextSecond
                font-normal
                text-lg
                pr-4
                leading-loose
                text-left
                pt-10
              "
            >
              {{ppl.biography}}
            </p>

            <div class="flex flex-col md:flex-row md:justify-between pt-10 pb-20">
              <div class="">
               
              </div>

              <div class="grid grid-flow-col pt-4 md:pt-0 gap-3 place-content-center">
                <button @click="previous()" class="w-14 h-14">
                  <div
                    class="
                      flex
                      rounded-xl
                      hover:shadow-2xl
                      transition
                      duration-500
                      ease-in-out
                      transform
                      hover:-translate-y-1 hover:scale-110
                    "
                  >
                    <img src="./../../assets/Svg/arrowleftR.svg" alt="" />
                  </div>
                </button>
                <button @click="next()" class="w-14 h-14">
                  <div
                    class="
                      flex
                      rounded-xl
                      hover:shadow-2xl
                      transition
                      duration-500
                      ease-in-out
                      transform
                      hover:-translate-y-1 hover:scale-110
                    "
                  >
                    <img src="./../../assets/Svg/arrowrightR.svg" alt="">
                  </div>
                </button>
              </div>
            </div>
          </div>
          <div class=" place-self-center ">
            <img :src="ppl.image" alt="">
         
          </div>
        </div>
      </div>
    
  </div>
</Carousel>
</template>
<script>
  import Carousel from "./../../components/base/carousel.vue";
  import staff from "@/data/staff";
  
  export default {
    components: {
      Carousel,
      
    },
  
    data() {
      return {
        staff: staff.data,
        carouselOptions: {
          contain: true,
          prevNextButtons: false,
          pageDots: false,
          wrapAround: true,
          // cellAlign: 'right',
          // contain: true
  
          // any options from Flickity can be used
        },
      };
    },
  
    methods: {
      next() {
        this.$refs.flickity.next();
      },
  
      previous() {
        this.$refs.flickity.previous();
      },
    },
  };
  </script>


<style scoped>

@media only screen and (min-width: 1025px) {
  .OurTeambgOne {
  background-image: url("./../../assets/Svg/OurTeamBg.svg");

}
.OurTeambgtwo {
  background-image: url("./../../assets/images/OurTeamBg.png");
  background-repeat: no-repeat;
  background-position-x: 80%;
  background-position-y: top;
}
.OurTeamDeco1 {
  background-image: url("./../../assets/Svg/Shapets.svg");
  background-repeat: no-repeat;
  background-position-x: 20%;
  background-position-y: 10%;
}
.OurTeamDeco2 {
  background-image: url("./../../assets/Svg/OurTeamDeco2.svg");
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: 10%;
}
.Teambg {
  background-image: url("./../../assets/Svg/OurTeam.svg");
}
.teammember{
  background-image: url("./../../assets/images/RamadanAliti.png");
   background-repeat: no-repeat;
   background-position-x: 50%;
   background-position-y: 50%;

}

}
@media only screen and (max-width: 1024px) {
  .OurTeamDeco1 {
  background-image: url("./../../assets/Svg/Shapets.svg");
  background-repeat: no-repeat;
  background-position-x: left;
  background-position-y: 30%;
}
.OurTeamDeco2 {
  background-image: url("./../../assets/Svg/OurTeamDeco2.svg");
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: top;
}

.Teambg {
  background-image: url("./../../assets/Svg/OurTeam.svg");
}

}
@media only screen and (max-width: 640px) {
  
}
.carousel-cell {
    display: block;
    /* padding-top: 50px;
    padding-inline: 20px;
    margin-right: 6%; */
    width: 100%;
    height: min-content;
  }
</style>