import pic_1 from "@/assets/images/Staff/Foto-Ramadan.png";
import pic_2 from "@/assets/images/Staff/Foto-Besmal.png";
import pic_3 from "@/assets/images/Staff/Foto-Feride.png";
import pic_4 from "@/assets/images/Staff/Foto-Suela.png";
import pic_5 from "@/assets/images/Staff/Foto-Puhiza.png";

const data = [
    {
        id: 0,
        image: pic_1,
        name:"Ramadan Aliti",
        title: "Education Director",
        biography:"Udhëtimi im në profesionin e fizikës ka nisur si inspirim nga një libër (viti i tretë i shkollës së mesme), ndërsa fillimi i STEMLab e ka nismën nga një telefonatë! Ideja është; predispozitat e personit, pastaj  fjalët dhe momentet ndryshme e përcaktojnë të ardhmen e individëve! Tanimë udhëtimet dhe aktivitetet janë mishëruar me STEM edukimin dhe të ardhmen e qëndrueshme të gjeneratave të reja… Udhëtojmë, hulumtojmë, lindin ide, krijojmë kontakte të reja, ndajm mendime dhe eksperienca për një botë më të mirë për të jetuar!",
    },
    {
        id: 1,
        image: pic_2,
        name:"No info!",
        title: "No info!",
        biography:"",
    },
    {
        id: 2,
        image: pic_3,
        name:"Feride Zeqiri-Shabani",
        title: "Trajnere e Brainobrain programit",
        biography:"Jam entuziaste të zbulojë njohuri të reja, për të ndihmuar gjeneratat e reja për një të ardhme më të ndritur. E vetmja mënyrë për të bërë punë të mëdha, është ta doni atë që bëni. Pikërisht të jesh në mesin e fëmijëve dhe ti motivosh ato që t’i zhvillojnë të dy hemisferat e trurit në të njëjtën kohë, të krijojnë vetbesim, saktësi dhe shpejtësi, është kënaqësia më e madhe si një edukatore. ",
    },
    {
        id: 3,
        image: pic_4,
        name:"Suela Rushiti",
        title: "Trajnere e IT dhe robotikës",
        biography:`Si person jam kurioze në kuptimin që më pëlqen të mësoj gjëra dhe informacione të reja. Ky kuriozitet dhe pasioni që kisha që në fëmijëri për teknologjinë bënë që të fillojë edhe rrugëtimi im si programere.
        Ky profesion përmban në vete shumë aftësi që duhen zotëruar, mbi të gjitha logjika dhe të menduarit logjik. Këtë tentoj që ta aplikoj tek gjeneratat e reja duke ndihmuar ato që t'i shprehin idetë, mendimet dhe zgjidhjet e tyre për një problem të caktuar. 
        Ëndrra e suksesit më motivon të studioj. Jo vetëm suksesi im por edhe i nxënësve të mi. `,
    },
    {
        id: 4,
        image: pic_5,
        name:"Puhiza Iseni",
        title: "Trajnere e IT dhe robotikës",
        biography:"Dashuria e parë ndaj profesionit tim ka filluar pikërisht në vitin e parë të shkollës së mesme, në atë periudhë ku u njoftova me elementet bazike të programimit. Programimi është fillimi i udhëtimeve të mija, ndërsa STEMLab është motivi im që ta vazhdoj këtë udhëtim me sukses edhe më tej. Përveç jetës profesionale më pëlqen të kalojë kohë duke lexuar, duke shikuar filma, duke bërë sport dhe duke kaluar kohë me familjarët e mi. Më pëlqen të jem e rrethuar me kolegët e mi dhe të bashkëpunojmë që të krijojmë një të ardhme sa më të mirë.",
    },
]
export default { data };